import { ReactNode, MouseEvent } from "react";
import { ReactComponent as EditIcon } from "../assets/Edit.svg";
import { ReactComponent as LocationIcon } from "../assets/Location.svg";
import { ReactComponent as RefreshIcon } from "../assets/Refresh.svg";
import "./Card.css";
import { useTranslation } from "react-i18next";

export const enum CardType {
  NORMAL = "normal",
  DOUBLE = "double",
  WIDE = "wide",
}

export interface CardProps {
  title: string;
  subtitle?: string;
  cardType?: CardType;
  children?: ReactNode;
  footer?: ReactNode;
  onEdit?: () => void;
  editAllowed?: boolean;
  loading?: boolean;
  onRefresh?: () => void;
}

const Card = ({
  title,
  subtitle,
  cardType = CardType.NORMAL,
  children,
  footer,
  loading,
  onEdit,
  editAllowed = true,
  onRefresh,
}: CardProps) => {
  const { t } = useTranslation();
  const handleEdit = (e: MouseEvent) => {
    e.preventDefault();
    onEdit?.();
  };

  return (
    <div className={`card card-${cardType}`}>
      <header>
        <div className="card-title-area">
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </div>

        {onEdit && editAllowed && (
          <button
            title={t("edit")}
            className="card-button"
            onClick={handleEdit}
          >
            <EditIcon />
          </button>
        )}
        {onRefresh && (
          <button
            title={t("refresh")}
            className="card-button"
            onClick={onRefresh}
            disabled={loading}
          >
            <RefreshIcon />
          </button>
        )}
      </header>

      <div className="card-content">
        {loading && <progress></progress>}
        {children}
      </div>

      <footer>{footer}</footer>
    </div>
  );
};

export interface CardContainerProps {
  children: ReactNode;
}

export const CardContainer = ({ children }: CardContainerProps) => {
  return <div className="card-container">{children}</div>;
};

export interface CardDetailsProps {
  icon?: ReactNode;
  value: ReactNode;
}

export const CardDetail = ({ icon, value }: CardDetailsProps) => {
  return (
    <div className="card-detail">
      {icon && <div className="card-detail-icon">{icon}</div>}
      <div className="card-detail-value">{value}</div>
    </div>
  );
};

export interface CardDetailTitledProps {
  title: string;
  value: string | null;
  type?: string | undefined;
}

export const CardDetailTitled = ({
  title,
  value,
  type,
}: CardDetailTitledProps) => {
  return (
    <div className="card-detail">
      <div className="card-col">{title}</div>
      <div className="card-col titled-card-detail-value">
        {type === "password" ? (
          <>{value?.replaceAll(/./g, "*")}</>
        ) : (
          <>{value}</>
        )}
      </div>
    </div>
  );
};

export interface CardGroupInfo {
  title?: string;
  subtitle?: string;
  children: ReactNode;
}

export const CardGroup = ({ title, subtitle, children }: CardGroupInfo) => {
  return (
    <div className="card-group">
      {title && <h1>{title}</h1>}
      {subtitle && <h2>{subtitle}</h2>}
      <div className="card-group-content">{children}</div>
    </div>
  );
};

export interface CardAddressInfoProps {
  street: string | null;
  city: string | null;
  zip: string | null;
  state?: string | null;
  country: string | null;
  useHeader?: boolean;
}

export const CardAddressInfo = ({
  street = "",
  city = "",
  zip = "",
  state = "",
  country,
  useHeader = true,
}: CardAddressInfoProps) => {
  const { t } = useTranslation();
  return (
    <div className="card-address-info">
      {useHeader && <h1>{t("address", { ns: "contact" })}</h1>}
      <div className="card-address-info-content">
        <div className="card-detail-icon">
          <LocationIcon />
        </div>
        <ul className="card-address-info-address">
          <li>{street}</li>
          <li>{city ? `${city} ${zip}` : ""}</li>
          <li>{state}</li>
          <li>{country}</li>
        </ul>
      </div>
    </div>
  );
};

export default Card;
