import { Contact, ContactCommonResponse } from "./contact";
import { DeviceListResponse } from "./device";

export enum CustomerSortableColumns {
  NAME = "NAME",
  ORGANIZATION = "ORGANIZATION",
  LIFTS = "LIFTS",
  CITY = "CITY",
  LAST_ACTIVITY = "LAST_ACTIVITY",
}

export type Customer = {
  id: number;
  firstName: string | null;
  lastName: string | null;
  organization: string | null;
  vat: string | null;
  email: string | null;
  phoneNr: string | null;
  address: string | null;
  apartmentNr: string | null;
  zipCode: string | null;
  city: string | null;
  region: string | null;
  country: string | null;
  contacts: Contact[] | null;
  accountOwner: AccountOwner;
};

export type AccountOwner = {
  email: string | null;
  phoneNr: string | null;
  firstName: string | null;
  lastName: string | null;
};

export type CustomerFullResponse = {
  id: number;
  firstName: string | null;
  lastName: string | null;
  organization: string | null;
  vat: string | null;
  email: string | null;
  phoneNr: string | null;
  address: string | null;
  apartmentNr: string | null;
  zipCode: string | null;
  city: string | null;
  region: string | null;
  country: string | null;
  tenantId: number;
  accountOwner: AccountOwner;
  devices: DeviceListResponse[] | null;
  contacts: ContactCommonResponse[] | null;
};

export type CustomerFormValues = Omit<Customer, "id" | "customer">;
