import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Card, { CardDetail, CardDetailTitled } from "../../../components/Card";
import { CliftReducerAction } from "../../../context/clift-context/clift-action-types";
import { useCliftContext } from "../../../hooks/useCliftContext";
import { DeviceState } from "../../../models/device";
import { getErrorCode } from "../../../services/clift-api-errors";
import { fetchDeviceState } from "../../../services/devices-api";

export interface DeviceStateCardProps {
  deviceId: number | undefined;
}

export const DeviceStateCard = ({ deviceId }: DeviceStateCardProps) => {
  const { cliftState, dispatchCliftState } = useCliftContext();
  const [deviceState, setDeviceState] = useState<DeviceState>();
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const { t } = useTranslation();

  const beautifyDate = (date: string) => {
    if (!date) {
      return "-";
    }
    return new Date(date).toLocaleString();
  };

  const updateDeviceState = (
    tenantId: number | undefined,
    deviceId: number | undefined
  ) => {
    if (!tenantId || !deviceId) {
      return;
    }
    setLoading(true);
    fetchDeviceState(tenantId, deviceId)
      .then((state) => {
        setFetchError(false);
        setLoading(false);
        const currentLocationTimestamp = beautifyDate(
          state.currentLocationTimestamp
        );
        if (state.faultCode === null || state.faultCode === "null") {
          state.faultCode = t("no_fault_code", { ns: "lift" });
        }
        setDeviceState({ ...state, currentLocationTimestamp });
      })
      .catch((err) => {
        setLoading(false);
        setFetchError(true);
        dispatchCliftState({
          type: CliftReducerAction.AddAlert,
          alert: t("lift_state_http_fail", {
            ns: "alerts",
            code: getErrorCode(err),
          }),
        });
      });
  };

  useEffect(() => {
    if (deviceId !== undefined && cliftState.currentTenant?.id) {
      updateDeviceState(cliftState.currentTenant?.id, deviceId);
    }
  }, [deviceId, cliftState.currentTenant]);

  return (
    <Card
      title={t("device_state", { ns: "lift" })}
      onRefresh={() =>
        updateDeviceState(cliftState.currentTenant?.id, deviceId)
      }
      loading={loading}
    >
      {fetchError && !loading && (
        <CardDetail
          value={t("lift_state_fetch_failed", { ns: "lift" })}
        ></CardDetail>
      )}
      {deviceState && !loading && (
        <>
          <CardDetailTitled
            title={t("fault_code", { ns: "lift" })}
            value={deviceState?.faultCode}
          />
          <CardDetailTitled
            title={t("current_location", { ns: "lift" })}
            value={deviceState?.currentLocation}
          />
          <CardDetailTitled
            title={t("current_location_timestamp", { ns: "lift" })}
            value={deviceState?.currentLocationTimestamp}
          />
          <CardDetailTitled
            title={t("foot_rest_position", { ns: "lift" })}
            value={deviceState?.footRestPosition}
          />
          <CardDetailTitled
            title={t("seat_position", { ns: "lift" })}
            value={deviceState?.seatPosition}
          />
          <CardDetailTitled
            title={t("hinge_position", { ns: "lift" })}
            value={deviceState?.hingePosition}
          />
          <CardDetailTitled
            title={t("swivel_position", { ns: "lift" })}
            value={deviceState?.swivelPosition}
          />
          <CardDetailTitled
            title={t("seatbelt_status", { ns: "lift" })}
            value={String(deviceState?.seatbeltStatus)}
          />
        </>
      )}
    </Card>
  );
};
