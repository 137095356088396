import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCliftContext } from "../../hooks/useCliftContext";
import { isRoleForAction } from "../../hooks/useRoles";
import { CliftReducerAction } from "../../context/clift-context/clift-action-types";
import { fetchChildTenant } from "../../services/tenants-api";
import { getErrorCode } from "../../services/clift-api-errors";
import { TenantResponseFull, TenantType } from "../../models/tenant";
import {
  EditTenantAllowedRoles,
  ViewTenantAllowedRoles,
} from "../../models/role";
import Page from "../../components/Page";
import Card, {
  CardAddressInfo,
  CardContainer,
  CardDetail,
  CardType,
} from "../../components/Card";
import { ReactComponent as BuildingIcon } from "../../assets/Building.svg";
import { ReactComponent as ProfileIcon } from "../../assets/Profile.svg";
import { TenantDetailContacts } from "./TenantDetailContacts";
import { TenantFormPages } from "./TenantForm";
import { EditTenant } from "../../app/tenants/EditTenant";

export const TenantDetail = () => {
  const isEditTenantAllowed: boolean = isRoleForAction(EditTenantAllowedRoles);
  const isViewTenantAllowed: boolean = isRoleForAction(ViewTenantAllowedRoles);
  const { cliftState, dispatchCliftState } = useCliftContext();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [tenant, setTenant] = useState<TenantResponseFull>();
  const { t } = useTranslation();

  const getTenantId = () => {
    const tenantID =
      params.tenantID !== undefined ? parseInt(params.tenantID) : undefined;
    return tenantID !== undefined && !isNaN(tenantID) ? tenantID : undefined;
  };

  const handleEditTenant = () => {
    dispatchCliftState({
      type: CliftReducerAction.SetSidePanelContent,
      content: <EditTenant tenantID={tenant?.id ?? 0} child={true} />,
    });
  };

  const handleEditAdmins = () => {
    dispatchCliftState({
      type: CliftReducerAction.SetSidePanelContent,
      content: (
        <EditTenant
          tenantID={tenant?.id ?? 0}
          child={true}
          page={TenantFormPages.TenantAdminPage}
        />
      ),
    });
  };

  const handleEditContacts = () => {
    if (!tenant) return;
    dispatchCliftState({
      type: CliftReducerAction.SetSidePanelContent,
      content: (
        <EditTenant
          tenantID={tenant?.id ?? 0}
          child={true}
          page={TenantFormPages.TenantContactPage}
        />
      ),
    });
  };

  useEffect(() => {
    if (!cliftState.currentTenant?.id) {
      return;
    }
    const tenantID = getTenantId();
    if (tenantID !== undefined && !cliftState.sidepanelContent) {
      setLoading(true);
      fetchChildTenant(cliftState.currentTenant.id, tenantID)
        .then((res) => {
          setLoading(false);
          setTenant(res);
        })
        .catch((err) => {
          setLoading(false);
          dispatchCliftState({
            type: CliftReducerAction.AddAlert,
            alert: t("tenant_http_fail", {
              ns: "alerts",
              code: getErrorCode(err),
            }),
          });
        });
    }
  }, [params.tenantID, cliftState.sidepanelContent, cliftState.currentTenant]);

  if (loading) {
    return <progress></progress>;
  }

  return (
    <Page
      title={tenant?.name ?? ""}
      subtitle={
        tenant?.id ? t("id", { ns: "translation" }) + " - " + tenant?.id : ""
      }
    >
      {tenant && isViewTenantAllowed && (
        <CardContainer>
          <Card
            title={
              cliftState.currentTenant?.tenantType === TenantType.ROOT
                ? t("market_tenant", { ns: "tenant" })
                : t("lift_provider_tenant", { ns: "tenant" })
            }
            onEdit={handleEditTenant}
            editAllowed={isEditTenantAllowed}
          >
            <CardDetail icon={<BuildingIcon />} value={tenant.name} />
            <CardDetail icon="VAT" value={tenant.businessId} />
            <CardAddressInfo
              street={tenant.address ?? ""}
              zip=""
              city=""
              country=""
            />
          </Card>
          <Card
            title={t("tenant_admins", { ns: "tenant" })}
            subtitle={t("tenant_detail_admins_title", { ns: "tenant" })}
            onEdit={handleEditAdmins}
            editAllowed={isEditTenantAllowed}
            footer={t("tenant_detail_admins_summary", {
              ns: "tenant",
              count: tenant.admins?.length ?? 0,
            })}
          >
            {tenant.admins &&
              tenant.admins.map((admin, i) => (
                <CardDetail
                  key={i}
                  icon={<ProfileIcon />}
                  value={admin.email}
                />
              ))}
          </Card>
          <Card
            title={t("tenant_support_numbers_title", { ns: "tenant" })}
            subtitle={t("tenant_support_numbers_hint", { ns: "tenant" })}
            cardType={CardType.DOUBLE}
            onEdit={handleEditContacts}
            editAllowed={isEditTenantAllowed}
          >
            <TenantDetailContacts
              contacts={tenant.contacts ?? []}
              onEditContacts={handleEditContacts}
            />
          </Card>
        </CardContainer>
      )}
    </Page>
  );
};
