import { CustomerListItem } from "models/customer-list-item";
import { Contact } from "../models/contact";
import {
  Customer,
  CustomerFormValues,
  CustomerFullResponse,
} from "../models/customer";
import { DeviceRegistrationItem } from "../models/device";
import { handleError } from "./clift-api-errors";
import { parseResponse } from "./response-parser";
import { Page, SearchParams } from "models/search";

export type CustomerDevice = {
  customerID?: number | undefined;
  deviceID?: number;
  removedFromCustomer?: boolean;
};

export const baseURL = "/api/tenant";

export const fetchCustomer = (
  tenantId?: number,
  customerID?: number
): Promise<CustomerFullResponse> =>
  fetch(`${baseURL}/${tenantId}/customers/${customerID}`, {
    method: "GET",
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const fetchCustomers = (
  search: SearchParams,
  tenantID?: number
): Promise<Page<CustomerListItem>> =>
  fetch(`${baseURL}/${tenantID}/customers`, {
    method: "POST",
    body: JSON.stringify(search),
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const createCustomer = (
  formValues: CustomerFormValues,
  tenantId: number,
  contacts: Contact[]
): Promise<void> =>
  fetch(`${baseURL}/${tenantId}/customers/add`, {
    method: "POST",
    body: JSON.stringify({
      // TODO: Backend sequence or something to create customer_id
      // Or remove this and use id instead
      customerId: "1",
      firstName: formValues.firstName?.length ? formValues.firstName : null,
      lastName: formValues.lastName?.length ? formValues.lastName : null,
      tenantId: tenantId > 0 ? tenantId : null,
      organization: formValues.organization?.length
        ? formValues.organization
        : null,
      vat: formValues.vat?.length ? formValues.vat : null,
      email: formValues.email?.length ? formValues.email : null,
      phoneNr: formValues.phoneNr?.length ? formValues.phoneNr : null,
      address: formValues.address?.length ? formValues.address : null,
      apartmentNr: formValues.apartmentNr?.length
        ? formValues.apartmentNr
        : null,
      city: formValues.city?.length ? formValues.city : null,
      zipCode: formValues.zipCode?.length ? formValues.zipCode : null,
      region: formValues.region?.length ? formValues.region : null,
      country: formValues.country?.length ? formValues.country : null,
      contacts,
    }),
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const deleteCustomer = (
  tenantID: number,
  customerID: number
): Promise<void> =>
  fetch(`${baseURL}/${tenantID}/customers/${customerID}`, {
    method: "DELETE",
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const addDeviceToCustomer = (
  tenantID: number,
  customerID: number,
  devices: DeviceRegistrationItem[]
): Promise<void> =>
  fetch(`${baseURL}/${tenantID}/customers/${customerID}/devices`, {
    method: "POST",
    body: JSON.stringify(devices),
  })
    .then(parseResponse)
    .catch((e) => handleError(e));

export const removeDeviceFromCustomer = (
  customerLift: CustomerDevice,
  tenantId: number
): Promise<void> =>
  fetch(
    `${baseURL}/${tenantId}/customers/${customerLift.customerID}/devices/${customerLift.deviceID}`,
    {
      method: "DELETE",
    }
  )
    .then(parseResponse)
    .catch((e) => handleError(e));

export const updateCustomer = (
  customer: Customer,
  formValues: CustomerFormValues,
  tenantId: number,
  contacts: Contact[]
): Promise<void> =>
  fetch(`${baseURL}/${tenantId}/customers/${customer.id}`, {
    method: "PUT",
    body: JSON.stringify({
      customerId: "1",
      firstName: formValues.firstName?.length ? formValues.firstName : null,
      lastName: formValues.lastName?.length ? formValues.lastName : null,
      tenantId: tenantId > 0 ? tenantId : null,
      organization: formValues.organization?.length
        ? formValues.organization
        : null,
      vat: formValues.vat?.length ? formValues.vat : null,
      email: formValues.email?.length ? formValues.email : null,
      phoneNr: formValues.phoneNr?.length ? formValues.phoneNr : null,
      address: formValues.address?.length ? formValues.address : null,
      apartmentNr: formValues.apartmentNr?.length
        ? formValues.apartmentNr
        : null,
      city: formValues.city?.length ? formValues.city : null,
      zipCode: formValues.zipCode?.length ? formValues.zipCode : null,
      region: formValues.region?.length ? formValues.region : null,
      country: formValues.country?.length ? formValues.country : null,
      accountOwner: {
        firstName: formValues.accountOwner.firstName,
        lastName: formValues.accountOwner.lastName,
        email: formValues.accountOwner.email,
        phoneNr: formValues.accountOwner.phoneNr,
      },
      contacts,
    }),
  })
    .then(parseResponse)
    .catch((e) => handleError(e));
